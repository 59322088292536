import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Learnmore from "../components/learnmore"
import AdvContent from "../components/adv/advContent"
import ScrollReveal from 'scrollreveal'

const Adv = () => {
  React.useEffect(()=> {
    ScrollReveal().reveal('.adv-foo',
      {
        delay:0,
        distance: '50px',
        easing: 'ease-in-out',
        origin: "bottom",
        mobile: false,
        duration: 400,
        interval: 50,
        opacity : 0, 
        reset: false,
        init: false
      }
    )
  })
  return (
    <Layout pageInfo={{ pageName: "adv" }}>
      <SEO title="Overseas Advertising" keywords={[`Advertising`, `Overseas`, `e-commerce`]} />
      {/* 大图 */}
      <div 
        className="adv-bg">
          <span className="adv-bg-1 adv-foo"></span>
          <span className="adv-bg-2 adv-foo"></span>
          <span className="adv-bg-3 adv-foo"></span>
          <span className="adv-bg-4 adv-foo"></span>


          <div className="adv-bg-text adv-foo">
            <div className="title">Overseas Advertising</div>
            <div className="text">Based on brand positioning, tailor the corporate image of various online media and accurately place ads to target audience.</div>
          </div>
      
      </div>
      <AdvContent />
      {/* 二级页脚 */}
      {/* <Learnmore /> */}
    </Layout>
  )
}

export default Adv

